.notifications-container {
  background-color: #f8f8f8;
  padding: 1rem 2.5rem 1.8rem;
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin-bottom: 0;
    display: flex;
    padding: 1.25rem 0;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #000;
    font-family: Rutledge;
    font-size: 1.37rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .kite-tabs__tab {
    margin-right: 0;
    &-content {
      color: #000;
    }

    &--active {
      .kite-tabs__tab-content {
        color: #0073d1;
        padding: 1rem 1.375rem;
      }
    }
  }
}
