.sent-notification-container {
  display: flex;
  padding: 1.25rem 2rem 2rem 2rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  background-color: #ffffff;

  .sent-notification-table {
    > tbody {
      tr {
        border: 1px solid #d8dde6;
      }
      td {
        padding: 1.2rem 0.75rem;
      }
    }
  }
}
