.create-notification-container {
  display: flex;
  padding: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  background-color: #ffffff;

  &__title {
    color: #000;
    font-family: Rutledge;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }

  &__input-container {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    width: 100%;

    &-multiline {
      display: flex;
      flex-direction: column;
      max-width: 25.18rem;

      .multiline-input-label {
        font-family: Rutledge;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.37rem;
      }
    }

    &-button {
      margin-top: 1.5rem;
    }
  }

  .message-text {
    height: 7.3rem;

    .kite-input__input {
      height: 100%;
      display: flex;
      text-align: left;
      vertical-align: top;
      box-sizing: border-box;
    }
  }
  .create-notification-summary-dialog {
    .kite-modal-dialog {
      border-radius: 0.25rem;
    }

    .kite-modal-body {
      padding: 0 3rem 2.25rem 3rem;
      .kite-modal-title {
        text-align: left;
      }
      .kite-btn {
        width: 100%;
      }
    }

    &__body-text {
      margin-bottom: 0;
      text-align: left;
      font-family: Rutledge;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.375rem;

      > span {
        font-family: Rutledge;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
      }
    }

    &__modal-buttons {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      margin-top: 1.5rem;

      .kite-btn__icon {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
