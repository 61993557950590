.manually-add-asset-form-container {
  &__heading {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: -0.0175rem;
    color: #003561;
    line-height: normal;
    margin-bottom: 1rem;
  }

  &__form {
    padding: 1.25rem 3rem;
    min-height: 48.25rem;
    position: relative;
    z-index: 1;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 0.0625rem solid #d8dde6;
  }

  &__form-one {
    &-heading {
      color: #033055 !important;
      font-family: 'Spectrum Sans';
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      margin-bottom: 0.375rem !important;
    }

    &-text {
      color: #033055;
      font-family: 'Spectrum Sans';
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__form-two {
    &-heading {
      color: #033055 !important;
      font-family: 'Spectrum Sans';
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      margin-bottom: 0.375rem !important;
    }

    &-sub-heading {
      color: #000;
      font-family: 'Spectrum Sans';
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    &-label {
      color: #000;
      font-family: 'Spectrum Sans';
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    &-text-area {
      max-width: 29.5rem;
    }
  }

  &__buttons {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1.5rem;
  }
}
